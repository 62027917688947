import React from "react";
import "styles/Login.scss";
import LoginForm from "./Login/LoginForm";
import logo from "img/logo.png";

/**--------Props--------
 * provider: Facebook Provider used to login with Facebook
 */

class Login extends React.Component {
  /**
   * constructor - sets initial state
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMainContent = () => {
    return (
      <div className="login-page-content" style={{ backgroundImage: `url(${logo})` }}>
        <LoginForm provider={this.props.provider} />
      </div>
    );
  };

  render() {
    return (
      <div className='home-page'>
        <div className="login-page">
            <div className='h-100'>
                {this.renderMainContent()}
            </div>            
            <div className='headline'>
                <h2>Coding Portal for Genie Academy's coding students.</h2>
            </div>
        </div>
        
        <footer className='footer'>
            <h2 className='title'>Features</h2>
            <div className='container feature'>
                <div>
                    <span></span>
                    <h5>in-built coding IDE</h5>
                </div>
                <div>
                    <span></span>
                    <h5>Create an Unlimited Project's</h5>
                </div>
                <div>
                    <span></span>
                    <h5>Distribute the project's output</h5>
                </div>
                <div>
                    <span></span>
                    <h5>Manage Project</h5>
                </div>
                <div>
                    <span></span>
                    <h5>Multiple programming languages support</h5>
                </div>
            </div>
            <br></br>
            <div className='container'>
                <ul>
                    <li>
                        <a href="https://www.genieacademy.com/about-us/privacy-policy?hsLang=en" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://www.genieacademy.com/about-us/terms-of-use?hsLang=en" rel="noopener noreferrer" target="_blank">Terms of Use</a>
                    </li>
                </ul>
            </div>
            <div className='container'>
                <p>
                   You can code your projects using HTML/CSS/JavaScript/Python. You need an activation from a Genie Academy Coach before you can use this.
                </p>
            </div>
        </footer>
      </div>
    );
  }
}

export default Login;
