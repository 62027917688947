import React from "react";
import "styles/FileView.scss";
import ViewportAwareButton from "./ViewportAwareButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload, } from "@fortawesome/free-solid-svg-icons";
import ReactResizeDetector from "react-resize-detector";
import { Dropdown, SplitButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as fetch from "../../../lib/fetch.js";
import { connect } from "react-redux";

/**--------Props---------------
 * files: array of files in current project
 */

class FileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFile: "New File",
      uploadFile: "Upload File",
      isSmall: false,
      imgFiles: [],
    };
    this.fileview = React.createRef();
    this.projectID = props.projectID;
  }

  // this function will be called when the component mounts, at the start if the component
  componentDidMount() {
    this.downloadFileData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectID !== this.props.projectID) {
      this.projectID = this.props.projectID;
      this.downloadFileData();
    }
  }

  downloadFileData = () => {
    if (this.props.projectID) {
      fetch.downloadFile(this.props.projectID)
        .then(response => response.json())
        .then(jsonData => {
          const updatedImgFiles = jsonData.fileData.map(item => ({
            id: item.id,
            url: item.data.url,
            name: item.data.name
          }));

          this.setState({ imgFiles: updatedImgFiles }); // Update state with new imgFiles
        })
        .catch(error => {
          console.error("Error fetching image:", error);
        });
    }
  };

  copyURL = (file) => {
    // Copy URL to clipboard
    navigator.clipboard
      .writeText(file.url)
      .then(() => {
        console.log("URL copied to clipboard:", file.url);
        alert(`Copied URL of ${file.name}`);
      })
      .catch((error) => {
        console.error("Error copying URL to clipboard:", error);
        // Handle error
      });
  };
  onFileShow = (index) => {
    this.props.showfile(index);
  };
  handleNewFile = () => {
    this.props.openNewModal();
  };
  rename = (index) => {
    this.props.openUpdateModal(
      index,
      this.props.langs[index],
      this.props.files[index]
    );
  };
  delete = (index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p> Do you really want to delete this file?</p>
            <Button variant="secondary" onClick={onClose}>
              No
            </Button>
            <Button
              varient="danger"
              onClick={() => {
                this.props.deleteFile(index);
                onClose();
              }}
            >
              Yes, Delete it!
            </Button>
          </div>
        );
      },
    });
  };

  handleChange = (event) => {
    let file = event.target.files[0];
    // Send file to backend
    let formData = new FormData();
    formData.append("file", file);
    fetch.uploadFile(this.props.projectID, formData)
      .then((res) => res.json())
      .then((data) => {

        // Update imgFiles in state using setState
        this.setState(prevState => ({
          imgFiles: [
            ...prevState.imgFiles,
            {
              id: null,
              name: data.fileData.name,
              url: data.fileData.url,
            }
          ]
        }));

        alert(`${data.fileData.name} file uploaded successfully`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  render() {
    // Access imgFiles from state instead of this.imgFiles
    const { imgFiles } = this.state;
    return (
      <ReactResizeDetector handleWidth>
        {({ width }) => (
          <div className="file-view" ref={this.fileview}>
            <div className="file-list">
              <ul>
                {this.props.files
                  ? this.props.files.map((file, index) => (
                    <li key={index}>
                      <SplitButton
                        drop="down"
                        className={
                          "file-link " +
                          (index === this.props.current ? "active" : "")
                        }
                        onClick={() => {
                          this.onFileShow(index);
                        }}
                        title={file}
                        variant="secondary"
                        id={`dropdown-button-drop-${index}`}
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => this.rename(index)}
                        >
                          Rename
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => this.delete(index)}
                        >
                          Delete
                        </Dropdown.Item>
                      </SplitButton>
                    </li>
                  ))
                  : ""}

                <li>
                  <h5 className="text-white pt-2 ml-1">Uploaded Images</h5>
                </li>

                {imgFiles && this.props.projectID ? (
                  imgFiles.map((file, index) => (
                    <li key={index}>
                      <SplitButton
                        drop="down"
                        className="file-link active my-1"
                        onClick={() => {
                          console.info("file details", {
                            id: file.id ? file.id : index,
                            name: file.name,
                            url: file.url,
                          });
                        }}
                        title={file.name}
                        variant="secondary"
                        id={`dropdown-button-drop-${file.id ? file.id : index}`}
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => this.copyURL(file)}
                        >
                          Copy URL
                        </Dropdown.Item>
                      </SplitButton>
                    </li>
                  ))
                ) : (
                  <li>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        padding: "8px",
                        borderRadius: "7px",
                      }}
                      className="file-link active my-2"
                    >
                      No Files are uploaded
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="new-file-button-wrapper">
              <div className="row g-2 flex-column">
                <div className="col mb-2">
                  <ViewportAwareButton
                    className="mx-1 btn-success"
                    size="lg"
                    onClick={this.handleNewFile}
                    isSmall={width <= 190}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    text={this.state.newFile}
                  />
                </div>

                {this.props.projectID && (
                  <div className="col upload-row">
                    <form>
                      <div>
                        <label
                          htmlFor="formFile"
                          className="btn btn-success btn-lg mx-1 custom-button">
                          <FontAwesomeIcon icon={faUpload} />
                          {width >= 190 ? (
                            <span className="d-none d-md-block">{this.state.uploadFile}</span>
                          ) : null}
                          <input
                            className="form-control"
                            onChange={this.handleChange}
                            accept="image/png"
                            multiple
                            type="file"
                            id="formFile"
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </ReactResizeDetector>
    );
  }
}

// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    projectUID: state.project.uid,
    projectID: state.project.id,
  };
};

// Connect component to Redux store
export default connect(mapStateToProps)(FileView);
